@if (authMode$ | async; as authMode) {
  <div class="side-nav-bar" [ngClass]="authMode">
    <div
      class="logo d-flex-column align-center justify-center white-bg"
      [ngClass]="{ 'white-bg': organization?.logo }"
      [routerLink]="appRoutes.home"
    >
      <img
        [class.default]="!organization?.logo"
        [src]="
          organization?.logo
            ? '/assets/logos/' + organization?.logo
            : '/assets/logo.svg'
        "
        alt="logo"
      />
    </div>

    <ul class="nav-links d-flex justify-center">
      @for (item of navConfig; track item.path) {
        <li
          routerLinkActive="active-link"
          [routerLink]="item.path"
          [routerLinkActiveOptions]="{ exact: item.isExactLink }"
          class="nav-link d-flex-center2"
        >
          <div class="wrap-icons">
            <app-icon height="20" [name]="item.icon"></app-icon>
          </div>

          <b>{{ item.text }}</b>
        </li>
      }
    </ul>

    <div
      *ifPermission="[appPermission.authSwitchMode]; else navigateToProfile"
      class="nav-link profile-menu"
      (click)="isRolesPopupOpen.set(true)"
    >
      <div class="wrap-icons">
        <app-icon [name]="getRoleIcon(authMode)"></app-icon>
      </div>

      <b>{{ authMode || "Personal" | titlecase }}</b>
    </div>

    @if (isRolesPopupOpen()) {
      <ng-container
        [ngTemplateOutlet]="roleSwitchPopup"
        [ngTemplateOutletContext]="{ authMode }"
      ></ng-container>
    }
  </div>
}

<ng-template #roleSwitchPopup let-authMode="authMode">
  <div class="role-switch-container">
    @for (item of authSwitchConfig; track item.mode) {
      @if (item.mode !== authMode) {
        <div class="role-item d-flex-center" (click)="setAuthMode(item.mode)">
          <app-icon height="20" [name]="item.icon"></app-icon>

          <div>
            <b>{{ item.title }}</b>
            <sub>{{ item.subtitle }}</sub>
          </div>
        </div>
      }
    }

    <hr class="separator" />

    <b
      class="link-item"
      [routerLink]="['/', appRoutes.Profile.base]"
      (click)="closePopup()"
    >
      Profile
    </b>

    <div class="layer" (click)="isRolesPopupOpen.set(false)"></div>
  </div>
</ng-template>

<ng-template #navigateToProfile>
  <div
    class="nav-link profile-menu"
    routerLinkActive="active-link"
    [routerLink]="appRoutes.Profile.base"
  >
    <div class="wrap-icons">
      <app-icon height="20" name="user-circle"></app-icon>
    </div>

    <b>Profile</b>
  </div>
</ng-template>
