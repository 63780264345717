import { Component } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { NavigationStart, Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { environment } from 'environments/environment';
import { Observable, filter, map } from 'rxjs';
import { AppRoutes } from 'src/core/enums/app-routes.enum';
import { LayoutState } from 'src/core/store/layout/layout-action.types';
import { toggleSideNavBar } from 'src/core/store/layout/layout.actions';
import { layoutStoreFeature } from 'src/core/store/layout/layout.selectors';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
  standalone: false,
})
export class AppComponent {
  public isAuthRoute$: Observable<boolean>;
  public layoutState$: Observable<LayoutState>;

  constructor(
    private titleService: Title,
    private router: Router,
    private store: Store,
  ) {
    this.titleService.setTitle(environment.title);
    this.layoutState$ = this.store.select(layoutStoreFeature);
    this.isAuthRoute$ = this.router.events.pipe(
      filter((event) => event instanceof NavigationStart),
      map(
        (event: any) =>
          event.url.includes(AppRoutes.Auth.base) ||
          event.url.includes(AppRoutes.invalidLink),
      ),
    );
  }

  public toggleSideNav() {
    this.store.dispatch(toggleSideNavBar());
  }
}
